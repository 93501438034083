import { useState, useEffect } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/footer.css';
import { typeViewSelector, scaleSelector } from '../../constants/arrays';
import AnimationSlide from './AnimationSlide';

export default function Footer({
  zoom,
  setZoom,
  setZoomOffset,
  viewType,
  setViewType,
  setRefresh,
  treeContainerRef,
  mainNode,
  startNode,
  setInputFocus,
}) {
  const [viewTypeIsOpen, setViewTypeOpen] = useState(false);
  const [selectedScaleView, setSelectedScaleView] = useState(false);

  const changeScale = (event) => {
    const fixEvent = event.toFixed(1);
    scaleSelector.map((i) => {
      if (Number(fixEvent) === i.scale) {
        setZoom({
          percentName: i.name,
          percentage: Number(fixEvent),
          positionName: i.positionName,
        });
        setZoomOffset({ x: i.offsetX, y: i.offsetY });
      }
      return null;
    });
  };

  const checkDimensions = () => {
    if (startNode !== mainNode) {
      if (treeContainerRef.current) {
        const { offsetWidth, offsetHeight } = treeContainerRef.current;

        const rect = treeContainerRef.current.getBoundingClientRect();
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        const lastPartWidth = (3 / 4) * screenWidth;
        const thirdPartWidth = (2 / 4) * screenWidth;

        const lastPartHeight = (3 / 4) * screenHeight;
        const thirdPartHeight = (2 / 4) * screenHeight;

        const overWidth = rect.right < screenWidth - 150;
        const overHeight = rect.bottom <= screenHeight;

        if (overWidth && overHeight) {
          if (offsetWidth > lastPartWidth && offsetHeight < lastPartHeight)
            changeScale(zoom.percentage + 0.1);
          else if (
            offsetWidth > thirdPartWidth &&
            offsetWidth < lastPartWidth &&
            offsetHeight < lastPartHeight
          )
            changeScale(zoom.percentage + 0.2);
          else if (
            offsetHeight < thirdPartHeight ||
            (offsetHeight > thirdPartHeight && offsetHeight < lastPartHeight)
          )
            changeScale(zoom.percentage + 0.3);
        }
      }
    } else {
      changeScale(1);
    }
  };

  useEffect(() => {
    setInputFocus(-1);
    checkDimensions();

    window.addEventListener('resize', checkDimensions);

    const observer = new ResizeObserver(checkDimensions);
    observer.observe(treeContainerRef.current);

    return () => {
      window.removeEventListener('resize', checkDimensions);
      if (treeContainerRef.current)
        observer.unobserve(treeContainerRef.current);
    };
  }, [startNode]);

  const scrollScale = (event) => {
    const delta = -Math.sign(event.deltaY);
    const step = 0.1;
    const newValue = zoom.percentage + delta * step;
    const clampedValue = Math.min(1.5, Math.max(0.1, newValue.toFixed(1)));
    scaleSelector.map((i) => {
      if (clampedValue === i.scale) {
        setZoom({
          percentName: i.name,
          percentage: clampedValue,
          positionName: i.positionName,
        });
        setZoomOffset({ x: i.offsetX, y: i.offsetY });
      }
      return null;
    });
  };
  return (
    <div className="footerContainer">
      <div className="viewContainer">
        <AnimationSlide
          show={viewTypeIsOpen}
          openMenu={setViewTypeOpen}
          state="downToUp"
        >
          <div className="viewSelector">
            {typeViewSelector.map((i) => (
              <div
                key={i.id}
                className="viewSelectorItemWrapper"
                onClick={() => {
                  setViewTypeOpen(false);
                  setViewType(i.name);
                  setRefresh((old) => old + 1);
                }}
              >
                <div className="font-14-reg">{i.name}</div>
                <div className="footer-check-mark">
                  {viewType === i.name && SvgSelector('checkMark')}
                </div>
              </div>
            ))}
          </div>
        </AnimationSlide>
        <div
          className="viewWrapper"
          onClick={() => setViewTypeOpen((old) => !old)}
        >
          <div className="font-14-med">{viewType}</div>
          {SvgSelector('chevronDown')}
        </div>
      </div>
      {viewType === 'Tree View' && (
        <div className="scaleContainer">
          <div
            className="scaleSelector"
            onWheel={(e) => {
              scrollScale(e);
            }}
          >
            <div
              className="hover cursor-pointer scale-selector-plus tooltipLeft"
              onClick={() => {
                changeScale(zoom.percentage + 0.1);
              }}
            >
              <span className="tooltiptextLeft font-10-med">Zoom in</span>
              {SvgSelector('plus', 'scale-selector-icon')}
            </div>
            <div className="tooltipLeft">
              <input
                type="range"
                min={0.1}
                max={1.5}
                className={`slider ${selectedScaleView && 'slider-selected'}`}
                step="0.1"
                value={zoom.percentage}
                onChange={(e) => {
                  changeScale(Number(e.target.value));
                }}
                onMouseDown={() => setSelectedScaleView(true)}
                onMouseUp={() => setSelectedScaleView(false)}
              />
              <span
                className="tooltiptextLeft scale-name-tooltip font-10-med"
                style={{ position: 'absolute', top: zoom.positionName }}
              >
                {zoom.percentName}
              </span>
            </div>
            <div
              className="hover cursor-pointer scale-selector-plus tooltipLeft"
              onClick={() => changeScale(zoom.percentage - 0.1)}
            >
              <span className="tooltiptextLeft font-10-med">Zoom out</span>
              {SvgSelector('negative', 'scale-selector-icon')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
